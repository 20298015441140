<template>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
                <!--  -->
            </div>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
                <strong>Slot: </strong> 
                <Tag class="mr-2" severity="danger" :value="total" v-if="total > max"></Tag>
                <Tag class="mr-2" :value="total" v-else></Tag>/
                <Tag class="mr-2" :value="max"></Tag>
            </span>
        </div>

</template>

<script>

export default {
   props: ['max', 'total'],
}
</script>